:root {
  // COLORS
  --primary: #e1e9ff;
  --darkBlue: #0313b0;
  --secondary: #e45858;
  --black: #2b2c34;
  --white: #fffffe;
  --gray: #a7a9be;
  --darkGray: #777;
  --red: #cc3939;
  --green: #0d8744;
  --orange: #fb552f;

  // OTHERS
  --text: #2b2c34;
  --background: #fafafa;
  --active: #cccccc25;
  --boxShadow: 0 3px 6px rgba(25, 30, 36, 0.16), 0 -1px 4px rgba(25, 30, 36, 0.04);
  --buttonBackground: #e5e7eb;
  --buttonBackground-hover: rgba(73, 70, 255, 0.19);
  --dropdownBoxShadow: #94a3b830 0px 0px 8px 0px;
  --headerHeight: 80px;
  --filterBackground: #f6f6f6;
  --filterBackgroundActive: #ececec;
  --filterColorBlur: #2b2c34;
  --rowBackground: #e1e9ff;
}

html,
body {
  margin: 0;
  padding: 0 !important;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: var(--text);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .cursor-pointer {
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 6px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: none;
    background: var(--gray);
  }
  ::-webkit-scrollbar-corner {
    background: var(--white);
  }
  p {
    margin: 0;
  }
  a {
    color: var(--primary);
  }
  @media only screen and (max-width: 768px) {
    --headerHeight: 60px
  }
}
