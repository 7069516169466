.productContainer {
  position: relative;
  cursor: pointer;
  width: 360px;
  border-radius: 8px !important;
  padding: 0 24px 24px 24px;
  background: #fff;
  box-sizing: border-box;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;

  @media only screen and (max-width: 768px) {
    width: 100%;
    border: 1px solid #ccc
  }

  &:hover {
    > .imageContainer {
      > img {
        scale: 1.2;
      }
    }
  }

  > .imageContainer {
    overflow: hidden;
    padding-top: 24px;
    > img {
      width: 100%;
      height: 242px;
      object-fit: contain;
      margin-bottom: 29px;
      transition: scale 0.3s ease-in-out;
      @media only screen and (max-width: 768px) {
        height: 160px;
      }
    }
  }
  > .title,
  > .description {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .description {
    font-size: 12px;
    font-weight: 400;
    -webkit-line-clamp: 2;
  }
  > .price {
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }
  > .category {
    margin: 8px 0;
    background-color: var(--primary);
    font-weight: 600;
    text-transform: capitalize;
  }
  > .rating {
    display: flex;
    align-items: center;
    gap: 4px;
    > .count {
      font-size: 12px;
      font-weight: 600;
      color: var(--text);
    }
  }
  > .addToCartButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    border-radius: 50000px;
    overflow: hidden;
    > button {
      background-color: var(--orange);
      padding: 8px 0 8px 8px;
      gap: 8px;
      min-width: unset;
    }
    span {
      overflow: hidden;
      white-space: pre;
      max-width: 0;
      font-size: 12px;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      span {
        max-width: 200px;
        padding-right: 8px;
      }
    }
  }
}
