.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  [class~="MuiInputBase-root"] {
    border-radius: 24px;
  }

  [class~="Mui-focused"] {
    [class~="MuiOutlinedInput-notchedOutline"] {
      border: none !important;
    }
  }

  input {
    width: 100%;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 600;
    color: var(--text) !important;
    background: transparent !important;
    border-radius: 24px;
    border: 1px solid var(--gray);
    &::-ms-input-placeholder {
      color: var(--gray);
      font-size: 12px;
      font-weight: 600;
    }
    &::placeholder {
      color: var(--darkGray);
      font-size: 12px;
      font-weight: 600;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line {
      transition:
        background-color 0s 600000s,
        color 0s 600000s;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: var(--darkGray);
    }
  }
}
