.dataControlContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px 16px;
  margin-bottom: 36px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
    gap: 8px;
    justify-content: center;
  }
}

.menuButton {
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--filterBackground) !important;
  border: 1px solid var(--darkGray) !important;
  text-transform: capitalize !important;
  border-radius: 24px !important;
  height: 38px !important;
  color: var(--filterColorBlur) !important;
  padding: 6px 16px !important;
  font-weight: 600 !important;

  > .action {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &:hover,
  &.active {
    background: var(--filterBackgroundActive) !important;
    color: var(--text) !important;
  }
}

.menu {
  [class~="MuiPaper-root"] {
    padding: 8px 16px 4px;
    overflow: visible;
    width: fit-content;
    transition: none !important;
    box-shadow: var(--dropdownBoxShadow);
  }
}

.priceSliderContainer {
  [class~="MuiSlider-root"] {
    width: 200px;
  }
  > span {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.ratingSliderContainer,
.priceSliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  > p {
    font-size: 14px;
    font-weight: 600;
  }
}

.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 400px;
  margin-left: auto;
  > .searchButton {
    position: absolute;
    width: 40px;
    right: 0;
    top: 0;
    > svg {
      width: 16px;
    }
  }
  input {
    padding-right: 40px !important;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    order: -1;
    margin-bottom: 8px;
  }
}
